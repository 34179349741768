.form-elegant .font-small {
    font-size: 0.8rem;
  }
  
  .form-elegant .z-depth-1a {
    -webkit-box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26), 0 4px 12px 0 rgba(121, 155, 254, 0.25);
    box-shadow: 0 2px 5px 0 rgba(55, 161, 255, 0.26), 0 4px 12px 0 rgba(121, 155, 254, 0.25);
  }
  
  .form-elegant .z-depth-1-half,
  .form-elegant .btn:hover {
    -webkit-box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28), 0 4px 15px 0 rgba(36, 133, 255, 0.15);
    box-shadow: 0 5px 11px 0 rgba(85, 182, 255, 0.28), 0 4px 15px 0 rgba(36, 133, 255, 0.15);
  }

  .loading {
    font-size: 30px; 
    color: white
}

.canvas {
  height:300px;
}
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.buttonCss {
  float: right;
  margin-right: 35px;
}

.heading {
  width: max-content;
}

.margin{
  margin-top: 80px;
  text-align: center;
}

h2{
margin-top: 20px;
text-align: center;
font-weight: 100;
}

.loading {
  font-size: 30px; 
  color: white
}